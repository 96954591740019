import React from 'react';


const DestinationBudgetMobile = ({
    budgetData,
    destinationData,
    showBudgetDetails,
    toggleBudgetDetails,
    stayDuration,
    origin,
    selectedPeople,
    selectedTransport
  }) => {
    if (!budgetData || budgetData.length === 0) {
      return (
        <div className="block">
          <div className="">
            <p>Pour connaître le budget d'un séjour à {destinationData.name}, renseignez les <a href="/" alt="retour à la page d'accueil">informations de votre voyage</a>.</p>
          </div>
        </div>
      );
    }
  
    const budget = budgetData[0];
  
    return (
      <div className="block">
        <div className="budget">
          {budget.budgets.totalLowTravelBudget === budget.budgets.totalHighTravelBudget ? (
            <div>{selectedPeople === 1 ? ( 
                <p>Selon vos critères de recherche, <strong>un séjour à {destinationData.name} pour {selectedPeople} personne</strong> est estimé à environ <strong>{budget.budgets.totalLowTravelBudget}€</strong></p>
                ) : (
                    <p>Selon vos critères de recherche, <strong>un séjour à {destinationData.name} pour {selectedPeople} personnes</strong> est estimé à environ <strong>{budget.budgets.totalLowTravelBudget}€</strong></p>
                )}
            </div>
          ) : (
            <div>{selectedPeople === 1 ? ( 
                <p>Selon vos critères de recherche, <strong>un séjour à {destinationData.name} pour {selectedPeople} personne</strong> est estimé <strong>entre {budget.budgets.totalLowTravelBudget}€ et {budget.budgets.totalHighTravelBudget}€</strong></p>
                ) : (
                    <p>Selon vos critères de recherche, <strong>un séjour à {destinationData.name} pour {selectedPeople} personnes</strong> est estimé <strong>entre {budget.budgets.totalLowTravelBudget}€ et {budget.budgets.totalHighTravelBudget}€</strong></p>
                )}
            </div>
          )}
        </div>
        <div>
          <div className="budget">
            {showBudgetDetails ? (
              <div>
                  <div>
                  {budget.budgets.transportBudgets.length === 0 ? (
                        <p>Transport non-inclus</p>
                    ) :
                    budget.budgets.transportBudgets.length === 1 ? (
                        <p>
                        {budget.budgets.transportBudgets[0].type} : {budget.budgets.transportBudgets[0].lowTransportBudget}€ - {budget.budgets.transportBudgets[0].highTransportBudget}€
                        </p>
                    ) : (
                        <div>
                        <strong>Transport depuis {origin} (au choix) : <br></br></strong>
                        <span>
                            {budget.budgets.transportBudgets
                            .slice()
                            .sort((a, b) => a.type.localeCompare(b.type))
                            .map((budget, index) => (
                                <span key={index}>{budget.type} : {budget.lowTransportBudget}€ - {budget.highTransportBudget}€<br></br></span>
                            ))}
                        </span>  
                        </div>
                    )}
                    {budget.budgets.totalAccommodationBudget > 0 ? (
                      <p><strong>Hébergement pour {stayDuration} nuit :</strong><br />
                        <span>{budget.budgets.totalAccommodationBudget}€ pour {selectedPeople}</span>
                        {selectedPeople === 1 ? ( <span> personne </span>) : (<span> personnes</span>)}
                      </p>
                    ) : (
                      <p><strong>{stayDuration} nuit :</strong><br />
                        Hébergement non-inclus
                      </p>
                    )}
                    {budget.budgets.totalFoodBudget > 0 ? (
                      <p><strong>Repas pour {stayDuration} jour :</strong><br />
                        <span>{budget.budgets.totalFoodBudget}€ pour {selectedPeople}</span>
                        {selectedPeople === 1 ? ( <span> personne </span>) : (<span> personnes</span>)}
                      </p>
                    ) : (
                      <p><strong>Repas pour {stayDuration} jour :</strong><br />
                        Repas non-inclus
                      </p>
                    )}
                  </div>
               
                <div className="small">
                  <p>Budgets à titre indicatif et non-contractuels.</p>
                </div>
              </div>
            ) : (
              <button className="btn-small" onClick={toggleBudgetDetails}>Afficher le détail du budget</button>
            )}
          </div>
        </div>
      </div>
    );
  };

const DestinationBudgetDesktop = ({
    budgetData,
    showBudgetDetails,
    toggleBudgetDetails,
    stayDuration,
    origin,
    selectedPeople,
    selectedTransport
  }) => {
    if (!budgetData || budgetData.length === 0) {
      return (
        <div className="block">
          <div className="">
            <h2>Budget estimé</h2>
            <p>Pour connaître le budget d'un séjour à {origin}, renseignez les <a href="/" alt="retour à la page d'accueil">informations de votre voyage</a>.</p>
          </div>
        </div>
      );
    }
  
    const budget = budgetData[0];
  
    return (
      <div className="block">
        <div className="budget">
          <h2>Budget estimé :</h2>
          <h2>{budget.budgets.totalLowTravelBudget}€ à {budget.budgets.totalHighTravelBudget}€</h2>
        </div>
        <div>
        <div>
                  {budget.budgets.transportBudgets.length === 0 ? (
                        <p>Transport non-inclus</p>
                    ) :
                    budget.budgets.transportBudgets.length === 1 ? (
                        <p>
                        {budget.budgets.transportBudgets[0].type} : {budget.budgets.transportBudgets[0].lowTransportBudget}€ - {budget.budgets.transportBudgets[0].highTransportBudget}€
                        </p>
                    ) : (
                        <div>
                        <strong>Transport depuis {origin} (au choix) : <br></br></strong>
                        <span>
                            {budget.budgets.transportBudgets
                            .slice()
                            .sort((a, b) => a.type.localeCompare(b.type))
                            .map((budget, index) => (
                                <span key={index}>{budget.type} : {budget.lowTransportBudget}€ - {budget.highTransportBudget}€<br></br></span>
                            ))}
                        </span>  
                        </div>
                    )}
                    {budget.budgets.totalAccommodationBudget > 0 ? (
                      <p><strong>Hébergement pour {stayDuration} nuit :</strong><br />
                        <span>{budget.budgets.totalAccommodationBudget}€ pour {selectedPeople}</span>
                        {selectedPeople === 1 ? ( <span> personne </span>) : (<span> personnes</span>)}
                      </p>
                    ) : (
                      <p><strong>{stayDuration} nuit :</strong><br />
                        Hébergement non-inclus
                      </p>
                    )}
                    {budget.budgets.totalFoodBudget > 0 ? (
                      <p><strong>Repas pour {stayDuration} jour :</strong><br />
                        <span>{budget.budgets.totalFoodBudget}€ pour {selectedPeople}</span>
                        {selectedPeople === 1 ? ( <span> personne </span>) : (<span> personnes</span>)}
                      </p>
                    ) : (
                      <p><strong>Repas pour {stayDuration} jour :</strong><br />
                        Repas non-inclus
                      </p>
                    )}
                  </div>
            <div className="small">
            <p>Budgets à titre indicatif et non-contractuels.</p>
            </div>
        </div>
    </div>
    ) 
  };

  
export {DestinationBudgetMobile, DestinationBudgetDesktop};
