import React, { useContext, useEffect, useState } from 'react';
import { supabase } from './supabaseClient'; 
import SearchContext from './SearchContext'; 
import { useParams } from 'react-router-dom';
import DestinationComponent from './destinationContent'; 
import { Helmet } from 'react-helmet';
import AddDestinationToTravelModal from './AddDestinationModal';
import {DestinationBudgetMobile, DestinationBudgetDesktop} from './DestinationBudget.js'; // Importez le nouveau composant

const API_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8080/';
const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com';

function DestinationPage() {
  const { searchParams } = useContext(SearchContext);
  const { id } = useParams();
  const [destinationData, setDestinationData] = useState(null);
  const [cityId, setCityId] = useState(null);
  const [origin, setSelectedCityName] = useState('');
  const [stayDuration, setStayDuration] = useState('');
  const [budgetData, setBudgetData] = useState(null);
  const [selectedPeople, setSelectedPeople] = useState('');
  const [selectedFood, setSelectedFood] = useState('');
  const [selectedAccommodation, setSelectedAccommodation] = useState('');
  const [selectedTransport, setSelectedTransport] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user || null);
    });
  }, []);

  useEffect(() => {
    const savedFormData = localStorage.getItem('searchFormData');
    if (savedFormData) {
      const formData = JSON.parse(savedFormData);
      if (formData.selectedPeople) setSelectedPeople(formData.selectedPeople);
      if (formData.selectedTransport) setSelectedTransport(formData.selectedTransport);
      if (formData.selectedAccommodation) setSelectedAccommodation(formData.selectedAccommodation);
      if (formData.selectedFood) setSelectedFood(formData.selectedFood);
      if (formData.stayDuration) setStayDuration(formData.stayDuration);
      if (formData.selectedCityName) setSelectedCityName(formData.selectedCityName);
    }
    console.log('Form data saved:', savedFormData);
  }, []);

  useEffect(() => {
    const destinationName = id;
    fetch(`${API_URL}api/cities/${destinationName}`)
      .then(response => response.json())
      .then(data => {
        const foundCityId = data[0]?.id;
        if (foundCityId) {
          setCityId(foundCityId);
        }
      })
      .catch(error => {
        console.error('Erreur lors de la récupération de l\'ID de la ville:', error);
      });
  }, [id]);

  useEffect(() => {
    if (cityId) {
      fetch(`${API_URL}api/destination/${cityId}`) 
        .then(response => response.json())
        .then(data => {
          setDestinationData(data);
        })
        .catch(error => {
          console.error('Error fetching destination data:', error);
        });
    }
  }, [cityId]);

  useEffect(() => {
    const savedFormData = localStorage.getItem('searchFormData');
    if (savedFormData) {
      const formData = JSON.parse(savedFormData);
      if (formData.selectedCity && cityId) {
        fetchBudgetData(
          formData.selectedCity, cityId, formData.selectedPeople, 
          formData.selectedAccommodation, formData.selectedFood, 
          formData.stayDuration, formData.selectedTransport
        );
      }
    }
  }, [cityId]);

  const transportParams = {
    train: selectedTransport.includes('train'),
    bus: selectedTransport.includes('bus'),
    car: selectedTransport.includes('car'),
    ferry: selectedTransport.includes('ferry')
  };

  const fetchBudgetData = (originId, destinationId, selectedPeople, selectedAccommodation, selectedFood, stayDuration, transport) => {
    fetch(`${API_URL}api/budget/?origin_id=${originId}&destination_id=${destinationId}&people=${selectedPeople}&accommodation=${selectedAccommodation}&food=${selectedFood}&stay_duration=${stayDuration}&transport=${JSON.stringify(transportParams)}`)
      .then(response => response.json())
      .then(data => {
        setBudgetData(data);
      })
      .catch(error => {
        console.error('Error fetching budget data:', error);
      });
  };

  const [showBudgetDetails, setShowBudgetDetails] = useState(false);
  const toggleBudgetDetails = () => {
    setShowBudgetDetails(!showBudgetDetails);
  };

  if (!destinationData) {
    return <div>Loading...</div>;
  }

  const canonicalUrl = `https://www.vaiago.com/destination/${destinationData.slug}`;

  return (
    <div className="App">    
      <Helmet>
        <title>Découvrez {destinationData.name} lors de votre prochain voyage</title>
        <meta name="description" content={`Tout ce que vous devez savoir avant de visiter ${destinationData.name} - ${destinationData.short_description}`} />
      </Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>
      {destinationData && <link rel="canonical" href={canonicalUrl} />}
      
      <div className="hero">
        <div className="hero-content">
           <h1>Découvrez {destinationData.name} lors de votre prochain voyage</h1>
            <p>{destinationData.short_description}</p>  
        </div>
        <div className="hidden-desktop">
        <img className="destination-img" src={`${FILES_URL}/img/destinations/${destinationData.slug}-cover.webp`} alt={`Visitez ${destinationData.name}`} />
        </div>
      </div>
      <div className="container">
        <div className="hidden-desktop">
          <DestinationBudgetMobile
            budgetData={budgetData}
            destinationData = {destinationData}
            showBudgetDetails={showBudgetDetails}
            toggleBudgetDetails={toggleBudgetDetails}
            stayDuration={stayDuration}
            origin={origin}
            selectedPeople={selectedPeople}
            selectedTransport={selectedTransport}
          />
        </div>
        <div className="destination-desc">
          <div className="block">
            <h2>Pourquoi visiter {destinationData.name} ?</h2>
            <div className="detailed-content">
              <DestinationComponent destinationData={destinationData} />
            </div>
          </div>
        </div>
        <div className="destination-details">
          <div className="block fixed-bottom-mobile">
            <div className="">
              <div className="hidden-mobile">
              <h2>Coup de coeur</h2>
              <p>Cette destination vous tente ?<br />Ajoutez-la à un projet de voyage.</p>
              <br /></div>
              {user ? (
                <button onClick={() => setIsModalOpen(true)}>Ajouter à un voyage</button>
              ) : (
                <a href="/inscription"><button>Ajouter à un voyage</button></a>
              )}
              <AddDestinationToTravelModal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} destinationId={cityId} />
            </div>
          </div>
          <div className="hidden-mobile">
            <DestinationBudgetDesktop
              budgetData={budgetData}
              showBudgetDetails={showBudgetDetails}
              toggleBudgetDetails={toggleBudgetDetails}
              stayDuration={stayDuration}
              origin={origin}
              selectedPeople={selectedPeople}
              selectedTransport={selectedTransport}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DestinationPage;
