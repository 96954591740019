import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { supabase } from './supabaseClient';
import SearchContext from './SearchContext';

const API_URL = process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:8080/';
const FILES_URL = 'https://vaiago.fra1.cdn.digitaloceanspaces.com'

function CountriesPage() {
    const { searchParams } = useContext(SearchContext);
    const [countries, setCountries] = useState([]);
    
    const [user, setUser] = useState(null); 
    const ref = useRef(null);

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setUser(session?.user || null);
        });
    }, []);
    
    useEffect(() => {
        console.log(`API_URL is set to: ${API_URL}`); // Cette ligne affichera l'URL utilisée
        fetch(`${API_URL}api/countries_with_destinations`)
            .then(response => response.json())
            .then(data => {
                setCountries(data);
            })
            .catch(error => {
                console.error('Error fetching countries:', error);
            });
    }, []);

    if (!countries || countries.length === 0) {
        return <div>Loading...</div>;
    }
    const canonicalUrl = `https://www.vaiago.com/pays`;

    return (
        <div className="App">    
            <Helmet>
                <title>Toutes les destinations Vaiago</title>
                <meta name="description" content="Découvrez toutes les destinations à découvrir sur Vaiago" />
                <link rel="canonical" href={canonicalUrl} />
                <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap" rel="stylesheet"/>
                <link href="https://fonts.googleapis.com/css2?family=montserrat&display=swap" rel="stylesheet"/>

            </Helmet>
            <div className="hero">
                <div className="hero-content">
                    <h1>Tous les pays où voyager avec Vaiago</h1>
                </div>
            </div>
            <div className="container">
                <div className="favorites">
                    {countries.map((country) => (
                        <Link to={`/${country.slug}`} key={country.slug} className="destination-link">
                            <div className="card-favorite">
                                <div className="picture">
                                    <img src={`${FILES_URL}/img/destinations/${country.min}-cover.webp`}  alt={`Visitez ${country.name}`} />
                                 </div>
                                 <div className="card-content-country">
                                    <h3>{country.name}</h3> 
                                    <p>{country.short_description}</p>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CountriesPage;  // Ensure the name matches the component declaration
