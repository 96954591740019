import React, { useState, useEffect, useRef } from 'react';
import './App.css';

const CustomSelect = ({ options, isMulti = false, selectedValues = [], onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Ensure selectedValues is always an array for consistency
    if (!Array.isArray(selectedValues)) {
      onChange([selectedValues]);
    }
  }, [selectedValues, onChange]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectOption = (option) => {
    if (isMulti) {
      if (selectedValues.includes(option)) {
        onChange(selectedValues.filter((item) => item !== option));
      } else {
        onChange([...selectedValues, option]);
      }
    } else {
      onChange(option);
      setIsOpen(false);
    }
  };

  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const displayValues = () => {
    const valuesArray = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    console.log('displayValues - valuesArray:', valuesArray); // Add log here
    if (!valuesArray || valuesArray.length === 0) {
      return placeholder;
    }
    const optionLabels = valuesArray.map(value => {
      const option = options.find(opt => opt.value === value);
      return option ? option.label : null;
    }).filter(label => label !== null);
    console.log('displayValues - optionLabels:', optionLabels); // Add log here
    return optionLabels.length > 0 ? optionLabels.join(', ') : placeholder;
  };

  return (
    <div className="custom-select-container" ref={containerRef}>
      <div className="custom-select-dropdown" onClick={handleDropdown}>
        {displayValues()}
        <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && (
        <div className="custom-select-options">
          {options.map((option) => (
            <div
              key={option.value}
              className={`custom-select-option ${selectedValues.includes(option.value) ? 'selected' : ''}`}
              onClick={() => handleSelectOption(option.value)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
