import React, { useState } from 'react';
import Modal from 'react-modal';
import { supabase } from './supabaseClient'; // Assurez-vous que ce chemin est correct

const CreateTravelModal = ({ isOpen, onRequestClose, onCreate }) => {
  const [travelName, setTravelName] = useState('');
  const [travelDescription, setTravelDescription] = useState('');
  const [error, setError] = useState(''); // Ajout de l'état d'erreur
  const [travels, setTravels] = useState([]);

  const submitForm = async (e) => {
    e.preventDefault();
    setError(''); // Réinitialiser l'erreur à chaque soumission
    const { data: { user } } = await supabase.auth.getUser()
    if (!user) {
      setError("Utilisateur non identifié.");
      return;
    }

    // Générer un token pour le voyage
    let token;
    if (crypto && crypto.randomUUID) {
      token = crypto.randomUUID(); // Utiliser crypto.randomUUID() s'il est disponible
    } else {
      // Générer un UUID v4 simple comme fallback
      // Ce code est juste un exemple simple, envisagez d'utiliser une librairie comme uuid pour de meilleures garanties d'unicité
      token = 'xxxx-xxxx-4xxx-yxxx-xxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }

    const { data, error } = await supabase.from('user_travels').insert([
      {
        user_id: user.id,
        name: travelName,
        description: travelDescription,
        share_token: token // Ajouter le token généré à l'objet à insérer
      },
    ]);

    if (error) {
      console.error('Erreur lors de la création du voyage:', error);
      setError('Impossible de créer le voyage.');
    } else {
      console.log('Voyage créé avec succès:', data);
      onRequestClose(); // Fermez la modale si la création est réussie
      if (onCreate) onCreate(); // Appel optionnel d'une fonction onCreate passée en props
      setTravels([...travels, data]);
      window.location.reload()
    }
};


  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>  
    <form onSubmit={submitForm} className="form-modal">
        <div className="close hidden-desktop"><button className="close-btn" onClick={onRequestClose}><i className="fa-solid fa-xmark"></i></button></div>
        <div className="input">
            <label className="label" htmlFor="name">Nom du voyage :</label>
            <input type="name" placeholder="Vacances d'été 2024" value={travelName} onChange={(e) => setTravelName(e.target.value)} required/> 
        </div>
        <div className="input">
            <label className="label" htmlFor="description">Description :</label>
            <textarea placeholder="Qu'avez-vous en tête pour ce voyage ?" value={travelDescription} onChange={(e) => setTravelDescription(e.target.value)} required></textarea>
        </div>
        <button type="submit">Sauvegarder</button>
        {error && <p className="error">{error}</p>}      
    </form>
    </Modal>
  );
};

export default CreateTravelModal;